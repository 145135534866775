.about {
  display: flex;
  flex-direction: column;
  background-color: #303a52;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
}

.about h4 {
  position: relative;
  text-align: center;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 2em;
}

.about h4:before {
  content: " ";
  position: absolute;
  bottom: -30px;
  border-radius: 10px;
  background-color: #fc85ae;
  height: 4px;
  width: 80px;
  left: 50%;
  margin-left: -40px;
}

.about img {
  height: 300px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px black;
}

/* .image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
} */
@media (min-width: 400px) {
  .about img {
    height: 350px;
  }
  .about h4 {
    font-size: 1.5rem;
    justify-self: center;
  }
}

@media (min-width: 576px) {
  .about {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 3em;
  }
  .about img {
    height: 450px;
  }
  .about h4:before {
    height: 8px;
    width: 150px;
    left: 50%;
    margin-left: -75px;
  }
  .about h4 {
    margin: 0;
    font-size: 1.8rem;
    justify-self: center;
    text-align: left;
  }
}

@media (min-width: 1000px) {
  .about {
    padding: 0 10em;
    gap: 8em;
  }
  .about img {
    height: 450px;
  }
  .about h4 {
    font-size: 2.5rem;
  }
  .about h4:before {
    height: 8px;
    width: 280px;
    left: 50%;
    margin-left: -140px;
  }
}
