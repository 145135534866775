@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  width: 0.25rem;
}
body::-webkit-scrollbar-track {
  background: #2b354a;
}
body::-webkit-scrollbar-thumb {
  background: #fc85ae;
  border-radius: 200px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
