@keyframes onHoverShadow {
  from {
    box-shadow: none;
  }
  to {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  }
}

.works-cards-wrapper {
  display: grid;
  width: 100%;
  gap: 1rem;
  grid-template-columns: repeat(1);
  place-content: center;
}

.works-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  border-radius: 20px;
  color: black;
  height: 300px;
  width: 300px;
  position: relative;
  overflow: hidden;
}

.works-card-overlay {
  position: absolute;
  inset: 0;
  opacity: 0;
  border-radius: 20px;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 1rem;
  font-weight: bold;
}

.works-card-overlay > h4 {
  color: white;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.works-card-overlay:hover {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 500ms ease-in-out;
}

.works-card:hover {
  animation-name: onHoverShadow;
  animation-duration: 500ms;
  animation-fill-mode: both;
}

.works-card-image-wrapper {
  background-color: #fc85ae;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: calc(20px - 0.5em);
  object-fit: cover;
  height: 70%;
  width: 100%;
  padding: 0.5em;
}

.works-card img {
  border-radius: calc(20px - 0.5em);
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: white;
}

.works-card-content {
  width: 100%;
  height: 30%;
  padding: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  overflow: hidden;
  line-clamp: 2;
}

@media screen and (min-width: 768px) {
  .works-cards-wrapper {
    grid-template-columns: repeat(2, 300px);
    gap: 0.8rem;
  }
}

@media screen and (min-width: 1024px) {
  .works-cards-wrapper {
    grid-template-columns: repeat(3, 300px);
    gap: 1rem;
  }
}
