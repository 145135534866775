@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  width: 0.25rem;
}
body::-webkit-scrollbar-track {
  background: #2b354a;
}
body::-webkit-scrollbar-thumb {
  background: #fc85ae;
  border-radius: 200px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.add-padding-top {
  padding-top: 10em;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 
Wave Text Animation Variables
*/
@-webkit-keyframes wave {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-35px);
  }
}
@keyframes wave {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-35px);
  }
}

#wavetext span {
  display: inline-block;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-name: wave;
          animation-name: wave;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

#wavetext :nth-child(6n + 0) {
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}

#wavetext :nth-child(6n + 1) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

#wavetext :nth-child(6n + 2) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

#wavetext :nth-child(6n + 3) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

#wavetext :nth-child(6n + 4) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

#wavetext :nth-child(6n + 5) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}

/* 
End of Wave Text Animation Variables
*/
.hero-bg {
  height: 100vh;
  background: #303a52 url(/static/media/background.60600157.jpeg);
  background-size: cover;
}

.hero-overlay {
  background: #303a52cc;
  height: 100vh;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-title {
  color: #fc85ae;
  font-size: 4.8rem;
  font-weight: bold;
  margin: 0;
}

.hero-bg p {
  font-size: 1.2rem;
  margin: 0 1em;
  font-weight: bold;
}

.hero-bg button {
  margin: 1.8em 0;
  border: 4px solid #fc85ae;
  background: none;
  padding: 0.5em 1.2em;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  transition-duration: 0.5s;
}

@media (min-width: 400px) {
  .hero-title {
    font-size: 6rem;
  }
  .hero-bg p {
    font-size: 1.4rem;
  }
  .hero-bg button {
    font-size: 1.3rem;
  }
}

@media (min-width: 576px) {
  .hero-wrapper {
    width: 600px;
  }
  .hero-title {
    font-size: 9rem;
  }
}

@media (min-width: 1000px) {
  .hero-wrapper {
    width: 900px;
  }
  .hero-title {
    display: inline-block;
    font-size: 14rem;
  }
  .hero-bg p {
    font-size: 2rem;
  }
}
/*# sourceMappingURL=Landing.css.map */
.logo {
  font-weight: bold;
  color: #fc85ae !important;
}

.nav-bar {
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  background-color: #2b354a !important;
  z-index: 99;
}

.navbar-dark .navbar-toggler {
  border: none;
}

.nav-bar.fixed {
  bottom: inherit;
  left: 0;
  position: fixed;
  top: 0;
}

.navbar-dark .navbar-nav .nav-link {
  position: relative;
  color: white;
  font-size: 1rem;
  font-weight: 800;
}

.navbar-nav {
  margin-left: auto;
}

.navbar-nav a {
  color: white;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fc85ae;
}
.navbar-dark .navbar-nav .nav-link.active:before {
  background-color: #fc85ae;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #fc85ae;
}

.navbar-dark .navbar-nav .nav-link:before {
  content: "";
  position: absolute;
  border-radius: 10px;
  margin-left: 0;
  width: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  transition: all 0.5s ease;
}

.navbar-dark .navbar-nav .active > .nav-link:before,
.navbar-dark .navbar-nav .nav-link.active:before,
.navbar-dark .navbar-nav .nav-link.show:before,
.navbar-dark .navbar-nav .show > .nav-link:before {
  width: 25px;
}

@media (min-width: 992px) {
  .navbar-dark .navbar-nav .nav-link:before {
    margin-left: 10px;
  }
}

.about {
  display: flex;
  flex-direction: column;
  background-color: #303a52;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
}

.about h4 {
  position: relative;
  text-align: center;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 2em;
}

.about h4:before {
  content: " ";
  position: absolute;
  bottom: -30px;
  border-radius: 10px;
  background-color: #fc85ae;
  height: 4px;
  width: 80px;
  left: 50%;
  margin-left: -40px;
}

.about img {
  height: 300px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px black;
}

/* .image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
} */
@media (min-width: 400px) {
  .about img {
    height: 350px;
  }
  .about h4 {
    font-size: 1.5rem;
    justify-self: center;
  }
}

@media (min-width: 576px) {
  .about {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 3em;
    gap: 3em;
  }
  .about img {
    height: 450px;
  }
  .about h4:before {
    height: 8px;
    width: 150px;
    left: 50%;
    margin-left: -75px;
  }
  .about h4 {
    margin: 0;
    font-size: 1.8rem;
    justify-self: center;
    text-align: left;
  }
}

@media (min-width: 1000px) {
  .about {
    padding: 0 10em;
    grid-gap: 8em;
    gap: 8em;
  }
  .about img {
    height: 450px;
  }
  .about h4 {
    font-size: 2.5rem;
  }
  .about h4:before {
    height: 8px;
    width: 280px;
    left: 50%;
    margin-left: -140px;
  }
}

.skills {
  position: relative;

  width: 100%;
  background-color: #4b5878;
  overflow: hidden;
  padding: 5em 1.2em;
}

.skill-watermark {
  position: absolute;
  top: -0.8em;
  left: -0.7em;
  white-space: nowrap;
  font-size: 40vw;
  font-weight: 800;
  color: #3f4c6c;
  z-index: 0;
}

.skill-card-wrapper {
  list-style: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
}

.skill-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.skills-header {
  position: relative;
  color: white;
  font-weight: 800;
  font-size: 2rem;
  margin-top: 0.2em;
}

.skills-header:before {
  content: " ";
  position: absolute;
  background-color: #fc85ae;
  height: 8px;
  width: 60px;
  border-radius: 20px;
  top: -10px;
}

.skill-card {
  display: flex;
  color: white;
  z-index: 1;
  margin: 0.8em 1em 0.8em 0em;
}
.skill-card-content {
  margin-left: 0.5em;
}

.skill-card svg {
  height: 100px;
  width: 100px;
  fill: white;
}

.skill-title {
  font-weight: bold;
}

.skill-name {
  background-color: #fc85ae;
  color: black;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 0.2em 0.35em;
  border-radius: 500px;
  margin: 0.2em 0.2em;
}

@media (min-width: 500px) {
  .skill-card svg {
    height: 135px;
    width: 135px;
    fill: white;
  }

  .skill-title {
    font-size: 1.3rem;
  }

  .skill-name {
    font-size: 0.85rem;
  }
}

@media (min-width: 1000px) {
  .skills {
    height: 100vh;
  }
  .skills-header:before {
    width: 115px;
    border-radius: 500px;
  }
  .skill-card svg {
    width: auto;
    height: 20vh;
  }

  .skills-header {
    font-size: 3rem;
    z-index: 1;
  }

  .skill-title {
    font-size: 1.4rem;
  }

  .skill-name {
    font-size: 0.9rem;
  }

  .skills {
    padding: 5em 5em;
  }

  .skill-card-wrapper {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 50px;
    gap: 50px;
  }
}

.works {
  color: white;
  background-color: #303a52;
  padding: 6.5em 0;
  min-height: 100vh;
  height:100%;
  display: flex;
  flex-direction: column;
}

.works-header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4em;
}

.works-header {
  position: absolute;
  font-size: 2.5rem;
  font-weight: 800;
}
.works-header:before {
  content: "";
  position: absolute;
  height: 5px;
  width: 80px;
  background: #fc85ae;
  border-radius: 100px;
  left: 50%;
  top: -10px;
  margin-left: -40px;
}



@-webkit-keyframes onHoverShadow {
  from {
    box-shadow: none;
  }
  to {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  }
}

@keyframes onHoverShadow {
  from {
    box-shadow: none;
  }
  to {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  }
}

.works-cards-wrapper {
  display: grid;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(1);
  place-content: center;
}

.works-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  border-radius: 20px;
  color: black;
  height: 300px;
  width: 300px;
  position: relative;
  overflow: hidden;
}

.works-card-overlay {
  position: absolute;
  inset: 0;
  opacity: 0;
  border-radius: 20px;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 1rem;
  font-weight: bold;
}

.works-card-overlay > h4 {
  color: white;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.works-card-overlay:hover {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 500ms ease-in-out;
}

.works-card:hover {
  -webkit-animation-name: onHoverShadow;
          animation-name: onHoverShadow;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.works-card-image-wrapper {
  background-color: #fc85ae;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: calc(20px - 0.5em);
  object-fit: cover;
  height: 70%;
  width: 100%;
  padding: 0.5em;
}

.works-card img {
  border-radius: calc(20px - 0.5em);
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: white;
}

.works-card-content {
  width: 100%;
  height: 30%;
  padding: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  overflow: hidden;
  line-clamp: 2;
}

@media screen and (min-width: 768px) {
  .works-cards-wrapper {
    grid-template-columns: repeat(2, 300px);
    grid-gap: 0.8rem;
    gap: 0.8rem;
  }
}

@media screen and (min-width: 1024px) {
  .works-cards-wrapper {
    grid-template-columns: repeat(3, 300px);
    grid-gap: 1rem;
    gap: 1rem;
  }
}

.contact {
  color: white;
  height: 100vh;
  background-color: #4b5878;
  padding: 5em 1em;
}

.contact-info {
  display: none;
  align-self: flex-start;
  margin-left: 9.5vw;
}

.contact li {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-top: 10px;
}

.contact li i,
.icon {
  color: #fc85ae;
  text-align: center;
  padding-right: 10px;
}
.contact li a {
  color: white;
  text-decoration: underline;
  text-align: center;
}

.contact-form-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.contact-header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-header {
  position: relative;
  font-size: 2rem;
  font-weight: 800;
}
.contact-header:before {
  content: "";
  position: absolute;
  height: 8px;
  width: 80px;
  background: #fc85ae;
  border-radius: 100px;
  left: 50%;
  margin-left: -40px;
  top: -12px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 450px;
  margin-top: 2em;
}

.contact-form input {
  color: white;
  background-color: #303a52;
  border: 2px solid #fc85ae;
  border-radius: 20px;
  padding: 8px;
  margin-bottom: 1em;
  font-size: 0.9rem;
  align-self: stretch;
}

.contact-form textarea {
  color: white;
  background-color: #303a52;
  border: 2px solid #fc85ae;
  border-radius: 20px;
  padding: 8px;
  margin-bottom: 1em;
  font-size: 0.9rem;
}

.contact-form button {
  background-color: #fc85ae;
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 20px;
  height: 50px;
  width: 150px;
  align-self: center;
}

.contact-form label {
  font-weight: 800;
  font-size: 0.9rem;
}

.contact-form textarea:focus,
.contact-form input:focus {
  outline: none;
  border: 2px solid #fc85ae;
  border-radius: 20px;
}

.contact-form .status-text {
  font-weight: bold;
  align-self: center;
}

.contact-form .error {
  color: red;
  font-size: 0.7rem;
  margin-top: -12px;
  margin-left: 12px;
}

@media (min-width: 1000px) {
  .contact-info {
    display: block;
    align-self: center;
  }

  .contact-form-wrapper {
    height: 100%;
    margin: -2em 0;
    align-items: center;
    justify-content: center;
  }
  .contact-header:before {
    width: 110px;
    left: 50%;
    margin-left: -55px;
  }

  .contact-header {
    font-size: 3rem;
  }
}

.footer {
  display: flex;
  height: 60px;
  color: white;
  align-items: center;
  justify-content: center;
  background-color: #2b354a;
}
.footer span {
  font-weight: bold;
}

